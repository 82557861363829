import { NoahDevicesTabType } from 'app/main/inventoryManagement/noahDevices/NoahDevicesTabType';
import { StockTransactionTabType } from 'app/main/inventoryManagement/stockTransactions/StockTransactionTabType';
import { PatientDetailTabType } from 'app/main/patients/detailX/PatientDetailTabType';
import { LumpSumType } from 'app/main/processes/requestsForInsuranceAllowance/forms/medicalTreatmentNomenclatures/LumpSumType';
import { StereoMonoContralateralType } from 'app/main/processes/requestsForInsuranceAllowance/forms/medicalTreatmentNomenclatures/StereoMonoContralateralType';
import { SortPropertyDescriptor } from 'framework/components/lists/SortPropertyDescriptor';
import {
	ApplicationLogoutMode,
	ArticlesInquiriesPrefilter,
	ArticlesInquiryLineStatusType,
	ArticlesInquiryStatusType,
	BatteryType,
	CalendarEventRequiredProperty,
	CommissionRuleMethod,
	ContactInfoType,
	CreditedStatus,
	CurrentLocationType,
	CustomerAccountType,
	DocumentAction,
	DocumentPredefinedType,
	ExactOnlineCustomerAccountPrefilter,
	ExactOnlineProductCatalogItemPrefilter,
	ExactOnlineSalePrefilter,
	ExpenseOrIncomeType,
	FunctionalClaim,
	Gender,
	HearingAidType,
	InventoryItemExportProp,
	InventoryStatus,
	LanguageOption,
	LeftRightOrStereoType,
	LinkViewContextType,
	LoanProcessStatus,
	LoansPrefilter,
	NoahDeviceType,
	NotificationType,
	OrderLineStatus,
	OrderPrefilter,
	OrderProcessStatus,
	Orientation,
	PageClaim,
	PatientDetailOverviewLayoutElementType,
	PatientRequiredProperty,
	PatientSearchMode,
	PatientStatus,
	PaymentExportProp,
	PaymentStatus,
	PredefinedPaymentMethod,
	ProductCatalogItemRizivHearingAidDefinitionV2LinkStatus,
	ProductCatalogItemType,
	PropertyOfType,
	PurchaseExportProp,
	PurchaseType,
	PurchasesPrefilter,
	RepairPrefilter,
	RepairProcessStatus,
	RepairProcessStep,
	RepairWarning,
	RfiaHandoverSaleGenerationMode,
	RfiaInvoiceProcessStep,
	RfiaStatus,
	RfiaWarning,
	RosaCalendarViewType,
	RosaEventStatus,
	SalesPrefilter,
	SalesSearchMode,
	SalesType,
	SpeechImageType,
	TemplateMainContext,
	TemplateType,
	TenderProcessStatus,
	TendersPrefilter,
	TimeUnit,
	TransactionType,
	UserRole,
	WorkflowType,
} from 'gen/ApiClient';
import { ScanError, ScanSource } from 'gen/imageScanner/ImageScannerClient';
import { IStrings } from 'localization/IStrings';
import { Frequency } from 'rrule';
import { ITimeUnitTranslation } from 'shared/timeUnit/ITimeUnitTranslation';
import { DocumentationType } from 'shared/utils/DocumentationType';

export const createRepairPrefilterRecord = (strings: IStrings): Record<RepairPrefilter, string> => {
	return {
		All: strings.all,
		InRepair: strings.inRepair,
		NotSentToSupplier: strings.notSendToSupplierYet,
		ToHandoverToPatient: strings.toHandoverToPatient,
		WithWarning: strings.warning,
	};
};

export const createRepairProcessStepRecord = (strings: IStrings): Record<RepairProcessStep, string> => {
	return {
		Define: strings.register,
		DeliverToPatient: strings.deliverToPatient,
		Redelivery: strings.redeliverVerb,
		SendToSupplier: strings.sendToSupplier,
	};
};

export const createExactOnlineCustomerAccountPrefilterRecord = (strings: IStrings): Record<ExactOnlineCustomerAccountPrefilter, string> => {
	return {
		All: strings.all,
		Linked: strings.linked,
		NotLinked: strings.notLinked,
	};
};

export const createExactOnlineProductCatalogPrefilterRecord = (strings: IStrings): Record<ExactOnlineProductCatalogItemPrefilter, string> => {
	return {
		All: strings.all,
		Linked: strings.linked,
		NotDeletedNorLinked: strings.toProcess,
		NotLinked: strings.notLinked,
	};
};

export const createExactOnlineSalesPrefilterRecord = (strings: IStrings): Record<ExactOnlineSalePrefilter, string> => {
	return {
		All: strings.all,
		Ignored: strings.ignored,
		NotIgnoredNorSynced: strings.toProcess,
		Synced: strings.synced,
	};
};

export const createAscSortPropertyRecord = (strings: IStrings): Record<SortPropertyDescriptor, string> => {
	return {
		date: strings.oldestFirst.toLowerCase(),
		number: strings.lowToHigh.toLowerCase(),
		price: `${strings.price} ${strings.lowToHigh.toLowerCase()}`,
		string: `A-Z`,
	};
};

export const createDescSortPropertyRecord = (strings: IStrings): Record<SortPropertyDescriptor, string> => {
	return {
		date: strings.newestFirst.toLowerCase(),
		number: strings.highToLow.toLowerCase(),
		price: `${strings.price} ${strings.highToLow.toLowerCase()}`,
		string: `Z-A`,
	};
};

export const createUserRoleRecord = (strings: IStrings): Record<UserRole, string> => {
	return {
		Admin: 'Administrator',
		Normal: strings.normal,
		SuperUser: 'Super-user',
	};
};

export const createTimeUnitRecord = (strings: IStrings): Record<TimeUnit, ITimeUnitTranslation> => {
	return {
		Seconds: { singular: strings.second, plural: strings.seconds, unknown: strings.secondOrSeconds },
		Minutes: { singular: strings.minute, plural: strings.minutes, unknown: strings.minuteOrMinutes },
		Hours: { singular: strings.hour, plural: strings.hours, unknown: strings.hourOrHours },
		Days: { singular: strings.day, plural: strings.days, unknown: strings.dayOrDays },
		Weeks: { singular: strings.week, plural: strings.weeks, unknown: strings.weekOrWeeks },
		Months: { singular: strings.month, plural: strings.months, unknown: strings.monthOrMonths },
		Years: { singular: strings.year, plural: strings.years, unknown: strings.yearOrYears },
	};
};

export const createTendersPrefilterRecord = (strings: IStrings): Record<TendersPrefilter, string> => {
	return {
		All: strings.all,
		Invoiced: strings.invoiced,
		NotLostAndExpired: strings.activeAndExpired,
		Expired: strings.expired,
	};
};

export const createTenderProcessStatusRecord = (strings: IStrings): Record<TenderProcessStatus, string> => {
	return {
		ConvertedToSales: strings.invoiced,
		Created: strings.created,
		Expired: strings.expired,
		Lost: strings.lost,
		Deleted: strings.deleted,
	};
};

export const createTemplateTypeRecord = (strings: IStrings): Record<TemplateType, string> => {
	return {
		RfiaEntLetter: strings.letterToEntPhysician,
		RfiaAdvisoryPhysicianLetter: strings.letterToAdvisoryPhysician,
		RfiaLoanSlip: `${strings.loanSlip} (${strings.requestForInsuranceAllowanceShort})`,
		RfiaTrialReport: strings.trialReport,
		RfiaAnamnesis: strings.anamnesis,
		RfiaCosi: strings.cosiQuestionnaire,
		Invoice: strings.invoice,
		Tender: strings.tender,
		RfiaHandoverCertificate: `${strings.handoverCertificate} (${strings.attachment} 12)`,
		OrderFormToCustomer: strings.orderFormToWho(strings.customer),
		OrderFormToSupplier: strings.orderFormToWho(strings.supplier),
		RepairFormToCustomer: strings.repairFormToWho(strings.customer),
		RepairFormToSupplier: strings.repairFormToWho(strings.supplier),
		SettlementProposal: strings.settlementProposal,
		LoanSlip: strings.loanSlip,
		TenderOrderForm: `${strings.orderForm} (${strings.tender})`,
		ReturnSlip: strings.returnSlip,
	};
};

// TODO translate
export const createTransactionTypeRecord = (strings: IStrings): Record<TransactionType, string> => {
	return {
		CreditPurchase: strings.creditNotePurchase,
		CreditSale: strings.creditNoteSale,
		Delivery: strings.delivery,
		EndLoan: strings.loanEnded,
		Order: strings.order,
		Purchase: strings.purchase,
		ReturnToSupplier: strings.returnedToSupplier,
		Sale: strings.sale,
		StartLoan: strings.loanStarted,
		ImportedFromNoah: strings.importedFromNoah,
		SentToSupplierForRepair: strings.sentToSupplierForRepair,
		MovedToOtherLocation: strings.toAnotherLocation,
		ReservationCleared: strings.reservationCleared,
		Reserved: strings.reserved,
		OrderCancelled: strings.orderCancelled,
		RegisteredAsSold: strings.historicalSale,
		RegisteredAsPurchasedByScript: strings.registeredAsPurchasedScript,
		DeliveryScript: `${strings.delivery} (script)`,
		RegisteredAsBroken: strings.registeredAsBroken,
		TreatmentArticleNeedLinked: strings.selectedInATreatment,
		TreatmentArticleNeedUnlinked: strings.removedFromATreatment,
		RegisteredAsSoldUndone: `(Historische) verkoop ongedaan gemaakt`,
		RegisteredAsBrokenUndone: `Geregistreerd als defect ongedaan gemaakt`,
	};
};

export const createSaleTypeRecord = (strings: IStrings): Record<SalesType, string> => {
	return {
		CashSale: strings.cashSale,
		Invoice: strings.invoice,
		CreditNote: strings.creditNote,
		AdvanceInvoice: strings.advanceInvoice,
	};
};

export const createWorkflowTypeRecordPlural = (strings: IStrings): Record<WorkflowType, string> => {
	return {
		Loan: strings.loans,
		Order: strings.orders,
		Repair: strings.repairs,
		Rfia: strings.requestForInsuranceAllowancesShortened,
		ArticlesInquiry: strings.articlesInquiries,
	};
};

export const createWorkflowTypeRecord = (strings: IStrings): Record<WorkflowType, string> => {
	return {
		Loan: strings.loan,
		Order: strings.order,
		Repair: strings.repair,
		Rfia: strings.requestForInsuranceAllowanceShort,
		ArticlesInquiry: strings.articlesInquiry,
	};
};

export const createTemplateMainContextRecord = (strings: IStrings): Record<TemplateMainContext, string> => {
	return {
		Patient: strings.patient,
		Rfia: strings.requestForInsuranceAllowance,
		Purchase: strings.purchase,
		Return: strings.return,
	};
};

export const createStockTransactionTabTitleRecord = (strings: IStrings): Record<StockTransactionTabType, string> => {
	return {
		'stock-movements': strings.stockMovements,
		deliveries: strings.deliveries,
		returns: strings.returns,
	};
};

export const createNoahDeviceTabTypeRecord = (strings: IStrings): Record<NoahDevicesTabType, string> => {
	return {
		'read-outs': strings.individualReadOutDevice,
		rules: strings.rulesForReadOuts,
	};
};

export const createLogoutModeRecord = (strings: IStrings): Record<ApplicationLogoutMode, string> => {
	return {
		Never: strings.neverLogoutAuto,
		After20Minutes: strings.logoutAfter20MinutesOfInactivity,
	};
};

export const createStereoMonoContraLateralRecord = (strings: IStrings): Record<StereoMonoContralateralType, string> => {
	return {
		stereo: strings.stereoPhonic,
		mono: strings.monoPhonic,
		contraLateral: strings.contraLateralEquipment,
	};
};

export const createSpeechImageTypeRecord = (strings: IStrings): Record<SpeechImageType, string> => {
	return {
		Aided: `${strings.withWhat(strings.hearingAids)} ({{TL_SI1}})`,
		AllComparison: `${strings.comparisonAll} ({{TL_SI2}})`,
		HeadphonesLeftRight: `${strings.headPhonesLeftRight} ({{TL_SI3}})`,
		StereoComparison: `${strings.comparisonStereo} ({{TL_SI4}})`,
		StereoComparisonDotted: `${strings.comparisonStereo} (${strings.dottedLine}) ({{TL_SI5}})`,
		StereoComparisonSmall: `${strings.comparisonStereo} (${strings.small}) ({{TL_SI6}})`,
		Unaided: `${strings.withoutWhat(strings.hearingAids)} ({{TL_SI7}})`,
	};
};

export const createScanSourceRecord = (strings: IStrings): Record<ScanSource, string> => {
	return {
		Auto: 'Auto',
		Default: 'Default',
		DuplexFromFeeder: 'Recto verso (van lader)',
		Feeder: 'Van lader',
		Flatbed: 'Van glasplaat',
	};
};

export const createScanErrorRecord = (strings: IStrings): Record<ScanError, string> => {
	return {
		DeviceNotFound: 'Scanner niet gevonden',
		NoFiles: 'Geen bestanden',
		SourceNotSupported: 'Bron wordt niet ondersteund',
	};
};

export const createSalesSearchModeRecord = (strings: IStrings): Record<SalesSearchMode, string> => {
	return {
		AllFields: strings.searchOnWhat(strings.allFields),
		Reference: strings.searchOnWhat(strings.reference),
		AmountToPay: strings.searchOnWhat(strings.amountToPay),
	};
};

export const createSalesPrefilterRecord = (strings: IStrings): Record<SalesPrefilter, string> => {
	return {
		All: strings.all,
		Overdue: strings.overDue,
		NotPaid: strings.withOutstandingBalance,
	};
};

export const createRruleFrequencyRecord = (strings: IStrings): Record<Frequency, string> => {
	return {
		'0': strings.year,
		'1': strings.month,
		'2': strings.week,
		'3': strings.day,
		'4': strings.hour,
		'5': strings.minute,
		'6': strings.second,
	};
};

// YEARLY = 0,
// MONTHLY = 1,
// WEEKLY = 2,
// DAILY = 3,
// HOURLY = 4,
// MINUTELY = 5,
// SECONDLY = 6

export const createRosaEventStatusRecord = (strings: IStrings): Record<RosaEventStatus, string> => {
	return {
		Active: strings.active,
		Canceled: strings.cancelled,
		Deleted: strings.deleted,
		InConsultation: strings.inConsulation,
		InWaitingRoom: strings.inWaitingRoom,
		NoShow: strings.noShow,
		Pending: strings.pending,
		Seen: strings.seen,
	};
};

export const createRosaCalendarViewTypeRecord = (strings: IStrings): Record<RosaCalendarViewType, string> => {
	return {
		Day: strings.day,
		Week: strings.week,
		Month: strings.month,
		Year: strings.year,
		List: strings.list,
		Timeline: strings.timeline,
	};
};

export const createRizivRefundableHearingInstrumentLinkStatusRecord = (
	strings: IStrings
): Record<ProductCatalogItemRizivHearingAidDefinitionV2LinkStatus, string> => {
	return {
		LinkedWithMultiple: strings.linkedToMultiple,
		LinkedWithSingle: strings.linked,
		NotApplicable: strings.notApplicableAbbreviation,
		NotLinked: strings.notLinked,
	};
};

export const createRfiaWarningRecord = (strings: IStrings): Record<RfiaWarning, string> => {
	return {
		ExpectedHandoverDateExpired: strings.expectedWhatExpired(strings.handoverDate),
		ExpectedReceptionAdvisoryPhysicianExpired: strings.expectedWhatExpired(`${strings.receptionDateOfDocuments} (${strings.advisoryPhysician})`),
		ExpectedReceptionMedicalPrescriptionExpired: strings.expectedWhatExpired(`${strings.receptionDateOfDocuments} (${strings.medicalPrescription})`),
		MedicalPrescriptionReceivedNotSentToAdvisoryPhysician: strings.medicalPrescriptionReceivedNotSentToAdvPhys,
		NoTrialStarted: strings.noTrialStartedYet,
		PrescriptionForTrialNotReceived: strings.noWhat(strings.prescriptionForTrial),
		TrialsExpiredNoDocumentsSentForMedicalPrescription: strings.trialsExpiredNoDocumentsSentForMedicalPrescription,
		TrialFailHandoverNotRegistered: strings.noWhatRegistered(strings.lumpSumContribution),
		HandedOverWithoutApprovals: strings.handedOverWithoutApprovals,
	};
};

export const createRfiaStatusRecord = (strings: IStrings): Record<RfiaStatus, string> => {
	return {
		Created: strings.created,
		PrescriptionForTrialReceived: strings.prescriptionForTrial,
		TrialRunning: strings.trialRunning,
		TrialEnded: strings.trialEnded,
		DocumentsSentToEntPhysician: strings.sentToEntPhysicianShort,
		MedicalPrescriptionReceived: strings.prescriptionReceived,
		DocumentsSentToAdvisoryPhysician: strings.sentToAdvisoryPhysicianShort,
		ApprovedByAdvisoryPhysician: strings.approvedByAdvisoryPhysicianShort,
		HandedOver: strings.handedOver,
		HandedOverAndPaid: strings.paid,
		Cancelled: strings.cancelled,
		FailedTrial: strings.failedTrial,
		HandedOverAndPaidBeforeApprovals: strings.paidBeforeApprovals,
		HandedOverBeforeApprovals: strings.handedOverBeforeApprovals,
	};
};

export const createRfiaInvoiceProcessStepRecord = (strings: IStrings): Record<RfiaInvoiceProcessStep, string> => {
	return {
		Tender: strings.createTenderAndOrderForm,
		AdvanceInvoice: strings.invoiceAdvance,
		Payment: strings.payVerb,
		HandoverBeforeApprovals: strings.invoicePriorToApprovals,
	};
};

export const createRfiaHandoverSaleGenerationModeRecord = (strings: IStrings): Record<RfiaHandoverSaleGenerationMode, string> => {
	return {
		GenerateSingleInvoice: strings.registerOneSale,
		GenerateMultipleInvoices: strings.registerMultipleSales,
		AskEveryTime: strings.askEveryTime,
	};
};

export const createRepairWarningRecord = (strings: IStrings): Record<RepairWarning, string> => {
	return {
		DeliveryOverDue: strings.deliveryOverDue,
		HandoverOverDue: strings.handoverOverDue,
		NoItemsDefined: strings.noWhatDefined(strings.items),
		NotSendToSupplier: strings.notSendToSupplierYet,
	};
};

export const createRepairStatusRecord = (strings: IStrings): Record<RepairProcessStatus, string> => {
	return {
		CancelledAfterSentToSupplier: strings.cancelledAfterShipment,
		CancelledBeforeSentToSupplier: strings.cancelledBeforeShipment,
		DeliveredToPatient: strings.deliveredToPatient,
		Redelivered: strings.redelivered,
		Registered: strings.registered,
		SentToSupplier: strings.sentToSupplier,
		PartialRedelivered: strings.partialDelivered,
	};
};

export const createPurchaseTypeRecord = (strings: IStrings): Record<PurchaseType, string> => {
	return {
		Invoice: strings.invoice,
		CreditNote: strings.creditNote,
	};
};

export const createPurchasesPrefilterRecord = (strings: IStrings): Record<PurchasesPrefilter, string> => {
	return {
		All: strings.all,
		Overdue: strings.overDue,
		NotPaid: strings.withOutstandingBalance,
	};
};

export const createPurchaseExportRecord = (strings: IStrings): Record<PurchaseExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		AmountPaid: strings.amountPaid,
		AmountToPay: strings.amountToPay,
		CountItems: `# ${strings.items}`,
		HasOutstandingBalance: `${strings.outstandingBalance}?`,
		HasPayments: `${strings.payments}?`,
		IsCreditNote: `${strings.creditNote}?`,
		IsFullyCredited: `${strings.fullyCredited}?`,
		IsFullyPaid: `${strings.fullyPaid}?`,
		IsNothingCredited: `${strings.nothingCredited}?`,
		IsNothingPaid: `${strings.nothingPaid}?`,
		IsOverDue: `${strings.overDue}?`,
		IsPartlyCredited: `${strings.statusPartlyCredited}?`,
		IsPartlyPaid: `${strings.partlyPaid}?`,
		OutstandingBalance: strings.outstandingBalance,
		PriceExVat: strings.priceExVat,
		PriceInVat: strings.priceInVat,
		Reference: strings.reference,
		SupplierIdentifier: strings.supplier,
		ValidUntil: strings.validTo,
		VatAmount: strings.vatAmount,
		ExternalReference: strings.externalReference,
		PurchaseDate: strings.referenceDate,
	};
};

export const createPaymentExportRecord = (strings: IStrings): Record<PaymentExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		AmountPaid: strings.amountPaid,
		CashSaleForPatientIdentifier: strings.cashSaleForPatient,
		CustomerAccountIdentifier: strings.accountReceivable,
		IsCashSale: `${strings.cashSale}?`,
		IsMultipleLines: `${strings.multipleLines}?`,
		IsSingleLine: `${strings.singleLine}?`,
		PaymentDate: strings.paymentDate,
		PaymentMethodIdentifier: strings.method,
		Reference: strings.reference,
		Comment: strings.remark,
		HasComment: `${strings.withWhat(strings.remark)}?`,
		RecipientsCsv: strings.patients,
		SaleOrPurchaseReferencesCsv: `${strings.references} (${strings.sale})`,
	};
};

export const createPatientStatusRecord = (strings: IStrings): Record<PatientStatus, string> => {
	return {
		Active: strings.active,
		Deceased: strings.deceased,
		Inactive: strings.noLongerActive,
	};
};

export const createPatientSearchModeToLabelRecord = (strings: IStrings): Record<PatientSearchMode, string> => {
	return {
		AllFields: strings.searchPatients,
		Name: strings.lastNameCommaFirstName,
		Phone: strings.phone,
		Address: strings.address,
		DateOfBirth: strings.dateOfBirth,
		SocialSecurityNumber: strings.socialSecurityNumber,
	};
};

export const createPatientDetailTabTypeTitleRecord = (strings: IStrings): Record<PatientDetailTabType, string> => {
	return {
		annotationsAndFollowUp: strings.annotations,
		noahData: `NOAH`,
		overview: strings.overview,
		personalData: strings.personalData,
		appointments: strings.appointments,
		documents: strings.documents,
		sales: strings.sales,
		workflows: strings.processes,
		tenders: strings.tenders,
	};
};

export const createPatientDetailOverviewLayoutElementTypeRecord = (strings: IStrings): Record<PatientDetailOverviewLayoutElementType, string> => {
	return {
		AssignedArticles: strings.actualEquipment,
		Comments: strings.annotations,
		LastAppointments: strings.nextAppointments,
		Sales: strings.sales,
		TonalAudiogram: strings.audiogram,
		Workflows: strings.processes,
	};
};

export const createPaymentStatusRecord = (strings: IStrings): Record<PaymentStatus, string> => {
	return {
		FullyPaid: strings.fullyPaid,
		NothingPaid: strings.nothingPaid,
		PartlyPaid: strings.partlyPaid,
	};
};

export const createPatientSearchModeRecord = (strings: IStrings): Record<PatientSearchMode, string> => {
	return {
		AllFields: strings.searchOnWhat(strings.allFields),
		Name: strings.searchOnWhat(strings.name),
		Phone: strings.searchOnWhat(strings.phoneNumberShort),
		Address: strings.searchOnWhat(strings.address),
		DateOfBirth: strings.searchOnWhat(strings.dateOfBirthShort),
		SocialSecurityNumber: strings.searchOnWhat(strings.socialSecurityNumberAbbreviation),
	};
};

export const createPatientRequiredPropertiesRecord = (strings: IStrings): Record<PatientRequiredProperty, string> => {
	return {
		Address: strings.address,
		AttendingAudiologist: strings.audiologist,
		AttendingEntPhysician: strings.entPhysician,
		AttendingGeneralPractitioner: strings.generalPractitioner,
		DateOfBirth: strings.dateOfBirth,
		Email: strings.email,
		HealthInsuranceFund: strings.healthInsuranceFund,
		OnLocation: strings.location,
		Origins: strings.origin,
		PhoneNumber: strings.phone,
		SocialSecurityNumber: strings.socialSecurityNumber,
		Labels: strings.labels,
		Reference: strings.number,
	};
};

export const createOrderStatusRecord = (strings: IStrings): Record<OrderProcessStatus, string> => {
	return {
		Cancelled: strings.cancelled,
		CancelledAfterPartialDelivery: strings.cancelledAfterPartlyDelivery,
		CompletelyDelivered: strings.delivered,
		PartialDelivered: strings.partlyDelivered,
		PlacedWithSupplier: strings.placed,
		Registered: strings.registered,
	};
};

export const createOrientationRecord = (strings: IStrings): Record<Orientation, string> => {
	return {
		Left: strings.left,
		Right: strings.right,
	};
};

export const createPageClaimRecord = (strings: IStrings): Record<PageClaim, string> => {
	return {
		BusinessData: strings.companyData,
		Calendar: strings.calendar,
		CashRegister: strings.cashDesk,
		CustomerAccounts: strings.accountsReceivable,
		Dashboard: strings.dashboard,
		Deliveries: strings.deliveries,
		EntPhysicians: strings.entPhysicians,
		GeneralPractitioners: strings.generalPractitioners,
		Loans: strings.loans,
		Locations: strings.locations,
		NoahDevices: strings.devicesFromNoah,
		Orders: strings.orders,
		Patients: strings.patients,
		PaymentMethods: strings.paymentMethods,
		Payments: strings.payments,
		ProductCatalog: strings.productCatalog,
		Purchases: strings.purchases,
		Repairs: strings.repairs,
		Returns: strings.returns,
		Rfias: strings.requestForInsuranceAllowances,
		Sales: strings.sales,
		SettlementProposals: strings.settlementProposals,
		Stock: strings.stock,
		StockMovements: strings.stockMovements,
		SupplierAccounts: strings.accountsPayable,
		Suppliers: strings.suppliers,
		TailorMadeProducts: strings.tailorMadeProducts,
		Templates: strings.templates,
		Tenders: strings.tenders,
		Users: strings.users,
		VatCustomers: strings.vatCustomers,
		AllCommissionPayouts: strings.allWhat(`${strings.payouts} (${strings.commissions})`),
		AllCommissions: strings.allWhat(strings.commissions),
		MyCommissionPayouts: strings.myWhat(`${strings.payouts} (${strings.commissions})`),
		MyCommissions: strings.myWhat(strings.commissions),
		DailyReceipts: strings.dailyReceipts,
		CashRegisterEntries: strings.cashRegisterAsLedger,
		SearchOnSerialNumbers: strings.searchOnWhat(strings.serialNumber),
		Hifs: strings.healthInsuranceFunds,
		StockEntries: `${strings.history} | ${strings.evolution}`,
		InventoryItems: strings.serialNumbers,
		ExactOnlineIntegration: strings.exactOnlineIntegration,
		RosaIntegration: strings.rosaIntegration,
		InvoiceForwardingIntegration: strings.invoiceForwardingIntegration,
	};
};

export const createPredefinedPaymentMethodRecord = (strings: IStrings): Record<PredefinedPaymentMethod, string> => {
	return {
		Bancontact: strings.bancontact,
		BankTransfer: strings.bankTransfer,
		Cash: strings.cash,
		CreditNote: strings.creditNote,
	};
};

export const createPropertyOfTypeRecord = (strings: IStrings): Record<PropertyOfType, string> => {
	return {
		Supplier: strings.supplier,
		Patient: strings.patient,
		HearingCentre: `${strings.hearingCentre} (${strings.ownProperty})`,
	};
};

export const createProductCatalogItemTypeRecord = (strings: IStrings): Record<ProductCatalogItemType, string> => {
	return {
		HearingInstrument: strings.hearingAid,
		Service: strings.service,
		Other: strings.other,
		RemoteControl: strings.remoteDevice,
		Insurance: strings.insurance,
		ProlongedWarranty: strings.prolongedWarranty,
		Charger: strings.charger,
		Dome: strings.dome,
		Earmould: strings.earmould,
		HearingProtection: strings.hearingProtection,
		Receiver: strings.receiver,
	};
};

export const createOrderPrefilterRecord = (strings: IStrings): Record<OrderPrefilter, string> => {
	return {
		All: strings.all,
		NotDeliveredYet: strings.notDeliveredYet,
		Overdue: strings.overdue,
		ToPlaceWithSupplier: strings.toPlaceWithSupplier,
	};
};

export const createOrderLineStatusRecord = (strings: IStrings): Record<OrderLineStatus, string> => {
	return {
		Cancelled: strings.cancelled,
		CancelledAfterPartialDelivery: strings.cancelledAfterPartlyDelivery,
		CompletelyDelivered: strings.delivered,
		PartiallyDelivered: strings.partialDelivered,
		NotDelivered: strings.notDeliveredYet,
	};
};

export const createLocalizedLanguageRecord = (strings: IStrings): Record<LanguageOption, string> => {
	return {
		en: strings.english,
		nl: strings.dutch,
		fr: strings.french,
	};
};

export const createNoahDeviceTypeRecord = (strings: IStrings): Record<NoahDeviceType, string> => {
	return {
		HearingInstrument: strings.hearingAid,
		RemoteControl: strings.remoteDevice,
		Unknown: strings.notApplicableAbbreviation,
	};
};

export const createNotificationTypeRecord = (strings: IStrings): Record<NotificationType, string> => {
	return {
		Information: strings.information,
		ReleaseNote: strings.releaseNote,
	};
};

export const createLoanStatusRecord = (strings: IStrings): Record<LoanProcessStatus, string> => {
	return {
		Cancelled: strings.cancelled,
		Ended: strings.ended,
		Started: strings.lent,
	};
};

export const createLinkViewContextTypeRecord = (
	strings: IStrings,
	templateMainContextTypeRecord: Record<TemplateMainContext, string>
): Record<LinkViewContextType, string> => {
	return {
		...templateMainContextTypeRecord,
		Cosi: strings.cosiQuestionnaire,
		Delivery: strings.delivery,
		InventoryItem: strings.inventoryItem,
		Loan: strings.loan,
		Order: strings.order,
		ProductCatalogItem: strings.productCatalogItem,
		Repair: strings.repair,
		RfiaHandover: strings.handoverCertificate,
		Sale: strings.sale,
		SettlementProposal: strings.settlementProposal,
		StockMovement: strings.fromArrowTo,
		Tender: strings.tender,
		TrialReport: strings.trialReport,
		ArticlesInquiry: strings.articlesInquiry,
	};
};

export const createLeftRightOrStereoRecord = (strings: IStrings): Record<LeftRightOrStereoType, string> => {
	return {
		Left: 'Links',
		Right: 'Rechts',
		Stereo: 'Stereo',
	};
};

export const createLoansPrefilterKeyTypeRecord = (strings: IStrings): Record<LoansPrefilter, string> => {
	return {
		All: strings.all,
		LentOut: strings.lent,
	};
};

export const createInventoryStatusRecord = (strings: IStrings): Record<InventoryStatus, string> => {
	return {
		Ordered: strings.inOrder,
		InStock: strings.inStock,
		InTestOrLoan: strings.inTest,
		InRepair: strings.inRepair,
		Sold: strings.statusSold,
		ReturnedToSupplier: strings.returnedToSupplier,
		NotInventoried: strings.notInventoried,
		Broken: strings.broken,
	};
};

export const createInventoryItemExportRecord = (strings: IStrings): Record<InventoryItemExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		Category: strings.category,
		CurrentLocationType: `${strings.currentLocation} (${strings.type})`,
		OrderDate: strings.orderDate,
		FirstDeliveryDate: strings.firstDeliveryDate,
		PurchaseDate: strings.purchaseDate,
		ReturnToSupplierDate: strings.returnDate,
		DateSold: strings.salesDate,
		HasBatteries: `${strings.batteries}?`,
		IsInRepair: `${strings.atSupplierForRepair}?`,
		IsElectronics: `${strings.electronics}?`,
		IsHearingInstrument: `${strings.hearingAid}?`,
		IsPurchaseCredited: `${strings.purchaseCredited}?`,
		IsPurchased: `${strings.statusPurchased}?`,
		IsReturnedToSupplier: `${strings.returnedToSupplier}?`,
		IsSalesCredited: `${strings.salesCredited}?`,
		IsSold: `${strings.statusSold}?`,
		IsTailorMade: `${strings.tailorMadeProduct}?`,
		Manufacturer: strings.manufacturer,
		Model: strings.model,
		ProductCatalogId: 'Id',
		SalesListPrice: strings.salesListPrice,
		PurchaseListPrice: strings.purchaseListPrice,
		SerialNumber: strings.serialNumber,
		SalesPrice: strings.salesPrice,
		Status: strings.status,
		Subcategory: strings.subcategory,
		VatPercentage: strings.vatPercentage,
		HasSerialNumberDefined: `${strings.hasSerialNumber}?`,
		IsInLoan: `${strings.lent}?`,
		IsInOrder: `${strings.inOrder}?`,
		IsRemoteControl: `${strings.remoteDevice}?`,
		Type: strings.type,
		CurrentLocationIdentifier: strings.currentLocation,
		PropertyOf: strings.propertyOf,
		PurchasePrice: strings.purchasePrice,
		DispatchDate: strings.dispatchDate,
		DispatchReference: strings.dispatchReference,
		ConsignmentReturnBeforeDate: `${strings.consignment}, ${strings.dateReturnBefore}`,
		HasPurchasedInsurance: `${strings.purchase} ${strings.insurance}?`,
		HasPurchasedProlongedWarranty: `${strings.purchase} ${strings.prolongedWarranty}?`,
		HasSoldInsurance: `${strings.sale} ${strings.insurance}?`,
		HasSoldProlongedWarranty: `${strings.sale} ${strings.prolongedWarranty}?`,
		IsDemo: `${strings.demo}?`,
		IsReserved: `${strings.reserved}?`,
		OldSerialNumbersCsv: `${strings.serialNumbers} (old)`,
		PatientIdentifier: strings.patient,
		ReservedForPatientIdentifier: `${strings.reserved} ${strings.patient}`,
		SupplierIdentifier: strings.supplier,
		PurchaseCreditDate: `${strings.date} - ${strings.creditNotePurchase}`,
		PurchaseCreditNoteReference: `${strings.reference} - ${strings.creditNotePurchase}`,
		PurchaseReference: strings.purchaseReference,
		SalesCreditDate: `${strings.date} - ${strings.creditNoteSale}`,
		SalesCreditNoteReference: `${strings.reference} - ${strings.creditNoteSale}`,
		SalesReference: strings.salesReference,
		BebatUnitPriceInVat: strings.bebatRate,
		PatientId: `ID ${strings.patient}`,
		ReservedForPatientId: `ID ${strings.reservedForAPatient}`,
		LocationWhereSoldId: `ID ${strings.locationWhereSold}`,
		LocationWhereSoldIdentifier: strings.locationWhereSold,
		LastLoanFromLocationIdentifier: `Laatste uitlening VAN locatie`,
		LastLoanStartedDate: `Start datum laatste uitlening`,
		PatientAge: `${strings.age} ${strings.patient}`,
		PatientNumber: `${strings.number} ${strings.patient}`,
		SoldInsuranceIdentifier: `${strings.insurance} (${strings.statusSold})`,
		SoldProlongedWarrantyIdentifier: `${strings.prolongedWarranty} (${strings.statusSold})`,
		DateSentToSupplierForRepair: `${strings.date} ${strings.repair} ${strings.sentToSupplier}`,
	};
};

export const createLumpSumRecord = (strings: IStrings): Record<LumpSumType, string> => {
	return {
		FailedTrial: strings.negativeEvaluationTrial,
		PassedAway: strings.deceased,
	};
};

export const createHearingAidTypeRecord = (strings: IStrings): Record<HearingAidType, string> => {
	return {
		BTE: `${strings.behindTheEar} (BTE)`,
		ITE: `${strings.inTheEar} (ITE)`,
	};
};

export const createGenderRecord = (strings: IStrings): Record<Gender, string> => {
	return {
		Male: strings.male,
		Female: strings.female,
		X: 'X',
	};
};

export const createFunctionalClaimRecord = (strings: IStrings): Record<FunctionalClaim, string> => {
	return {
		vepp: strings.viewEditWhat(strings.purchasePrices),
		cenhaxf: strings.exportPatientToNhaxFile,
	};
};

export const createExpenseOrIncomeTypeRecord = (strings: IStrings): Record<ExpenseOrIncomeType, string> => {
	return {
		Expense: strings.expense,
		Income: strings.income,
		NotApplicable: strings.notApplicableAbbreviation,
	};
};

export const createDocumentPredefinedTypeRecord = (
	strings: IStrings,
	templateTypeRecord: Record<TemplateType, string>
): Record<DocumentPredefinedType, string> => {
	return {
		...templateTypeRecord,
		RfiaPrescriptionScan: `${strings.prescription} (${strings.attachment} 17)`,
		DeliveryNote: strings.deliveryNote,
		RfiaProformaHandoverCertificate: `${strings.attachment} 12 (${strings.proforma})`,
	};
};

export const createDocumentationTypeRecord = (strings: IStrings): Record<DocumentationType, string> => {
	return {
		'riziv-numbering-info': 'RIZIV documentatie nummering',
		'sin-riziv-info': 'RIZIV documentatie spraak in ruis',
		'speech-riziv-info': 'RIZIV documentatie spraaktesten',
		'bebat-tarifs': 'Bebat tarieven',
		'recupel-tarifs': 'Recupel tarieven',
		'clt-riziv-info': 'CLT documentatie',
	};
};

export const createDocumentActionRecord = (strings: IStrings): Record<DocumentAction, string> => {
	return {
		CreatedFromTemplate: strings.createdBracketsHow(strings.fromTemplate),
		CreatedFromUploadWithoutSignature: strings.createdBracketsHow(strings.upload),
		CreatedFromUploadWithSignature: strings.createdFromUploadWithSignature,
		Saved: strings.updated,
		ReplacedWithoutSignature: strings.scannedOrUploaded,
		ReplacedWithSignature: strings.signatureScannedOrUploaded,
		RebuildFromTemplate: strings.rebuildFromTemplate,
	};
};

export const createCurrentLocationTypeRecord = (strings: IStrings): Record<CurrentLocationType, string> => {
	return {
		Supplier: strings.atSupplier,
		Location: strings.inStockOnALocation,
		Patient: strings.atPatient,
		Unknown: strings.unknown,
	};
};

export const createCreditedStatusRecord = (strings: IStrings): Record<CreditedStatus, string> => {
	return {
		FullyCredited: strings.statusCredited,
		PartlyCredited: strings.statusPartlyCredited,
		NothingCredited: strings.statusNotCredited,
	};
};

export const createCommissionRuleMethodRecord = (strings: IStrings): Record<CommissionRuleMethod, string> => {
	return {
		TotalValueOfDevices: strings.totalValueOfDevices,
		TotalValueOfSale: strings.totalValueOfSale,
	};
};

export const createBatteryTypeCaptionRecord = (strings: IStrings): Record<BatteryType, string> => {
	return {
		'10': strings.b10Caption,
		'13': strings.b13Caption,
		'312': strings.b312Caption,
		'675': strings.b675Caption,
		R: strings.rechargeableBatteries,
	};
};

export const createArticlesInquiriesPrefilterKeyTypeRecord = (strings: IStrings): Record<ArticlesInquiriesPrefilter, string> => {
	return {
		All: strings.all,
		Unassigned: strings.unassigned,
	};
};

export const createArticlesInquiryLineStatusTypeRecord = (strings: IStrings): Record<ArticlesInquiryLineStatusType, string> => {
	return {
		Assigned: strings.assigned,
		PartialAssigned: strings.partialAssigned,
		Unassigned: strings.unassigned,
	};
};

export const createArticlesInquiryStatusTypeRecord = (strings: IStrings): Record<ArticlesInquiryStatusType, string> => {
	return {
		Assigned: strings.assigned,
		PartialAssigned: strings.partialAssigned,
		Unassigned: strings.unassigned,
		Cancelled: strings.cancelled,
	};
};

export const createBatteryTypeRecord = (strings: IStrings): Record<BatteryType, string> => {
	return {
		'10': '10',
		'13': '13',
		'312': '312',
		'675': '675',
		R: strings.rechargeable,
	};
};

export const createCalendarEventRequiredPropertiesRecord = (strings: IStrings): Record<CalendarEventRequiredProperty, string> => {
	return {
		Contact: strings.contact,
		Description: strings.description,
		EventType: strings.type,
		Room: strings.room,
		Title: strings.title,
	};
};

export const createContactInfoTypeRecord = (strings: IStrings): Record<ContactInfoType, string> => {
	return {
		Email: strings.email,
		Phone: strings.phone,
	};
};

export const createCustomerAccountTypeRecord = (strings: IStrings): Record<CustomerAccountType, string> => {
	return {
		Company: strings.vatCustomer,
		HealthInsuranceFund: strings.healthInsuranceFundShort,
		Patient: strings.patient,
	};
};
